/* eslint-disable vue/multi-word-component-names */
/* eslint-disable vue/no-reserved-component-names */
import { createApp } from "vue";
import PrimeVue from "primevue/config";
import router from "./router";
import App from "./App.vue";
import ColorPicker from "primevue/colorpicker";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import "primevue/resources/themes/md-dark-indigo/theme.css";
import "/node_modules/primeflex/primeflex.css";
import VueDOMPurifyHTML from "vue-dompurify-html";

delete window.history.pushState; // workaround to make sure vue router doesn't try using pushState
delete window.history.replaceState; // workaround to make sure vue router doesn't try using replaceState
const app = createApp(App);

app.use(PrimeVue);
app.use(router);
app.use(VueDOMPurifyHTML);
app.component("ColorPicker", ColorPicker);
app.component("Dialog", Dialog);
app.component("Button", Button);

window.Office.onReady(() => {
  app.mount("#app");
});
